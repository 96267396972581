import {
  DISPLAY_STATUSES,
  STATUSES,
} from '../../../../../constants/order-status'
import {
  convertToMinutes,
  getTimeWithOffset,
  isToday,
} from '../../../../../utils/date/dates'

export default function determineOrderLateStatus(
  status: string | null,
  timezoneOffset: string | undefined,
  startTime: string | undefined,
  fromDateGenerated: string | undefined,
) {
  if (!timezoneOffset) return status

  let orderVisualStatus = status
  const timeInOffsetZone = getTimeWithOffset(timezoneOffset)

  if (!fromDateGenerated || !startTime || !isToday(fromDateGenerated))
    return orderVisualStatus

  const startTimeInMinutes = convertToMinutes(startTime)
  const timeInOffsetZoneInMinutes = convertToMinutes(timeInOffsetZone)
  const differenceInMinutes = timeInOffsetZoneInMinutes - startTimeInMinutes

  if (status === STATUSES.scheduled) {
    if (differenceInMinutes >= -15 && differenceInMinutes < 30) {
      //starting at 15 minutes before appointment & up until 30 minutes after appointment starts
      orderVisualStatus = DISPLAY_STATUSES.almost_late
    } else if (differenceInMinutes >= 30) {
      //30 minutes after appointment starts
      orderVisualStatus = DISPLAY_STATUSES.late
    }
  }

  return orderVisualStatus
}
