import { useState, useContext } from 'react'
import WODetailsModal from '../sub-modals/WODetailsSubModal'
import styles from './styles.module.css'
import { WoDetailContext } from '../../../context/context'
import {
  CANCELLED_STATUSES,
  STATUSES,
} from '../../../../../global/constants/order-status'
import { SUB_MODAL_NAMES } from '../sub-modals/constants'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../../global/constants/permissions'
import hasPermission from '../../../../../global/utils/user/has-permission'

interface IAdditionalActionsProps {
  isReadOnly: boolean
}

const { CATEGORY, ENTRIES } = PERMISSIONS.WORK_ORDERS

export default function AdditionalActions({
  isReadOnly,
}: IAdditionalActionsProps) {
  const { user } = useContext(UserContext)
  const { data: order } = useContext(WoDetailContext)

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalName, setModalName] = useState<string>('')

  function handleOpen(e: any) {
    setModalName(e.target.innerText)
    setModalOpen(true)
  }
  function handleClosed() {
    setModalOpen(false)
    setModalName('')
  }

  const shouldEnableResendInvoice =
    !isReadOnly && order?.status === STATUSES.completed
  const shouldEnableScheduledToPending =
    !isReadOnly && order?.status === STATUSES.scheduled
  const shouldEnableCompleteWorkOrder =
    !isReadOnly &&
    ![...CANCELLED_STATUSES, STATUSES.completed].includes(
      order?.status ?? '',
    ) &&
    (order?.invoices?.find((invoice) => invoice.paymentMethodToken) ||
      order?.freeOrderReason) &&
    order?.status !== STATUSES.quote
  const shouldDisableForceAppointment =
    isReadOnly ||
    [...CANCELLED_STATUSES, STATUSES.completed].includes(order?.status ?? '') ||
    !hasPermission(user).allow(CATEGORY, ENTRIES.FORCE_APPOINTMENT.NAME)

  return (
    <div>
      <div className={styles.heading}>Additional Actions</div>
      <WODetailsModal
        isOpen={modalOpen}
        handleClosed={handleClosed}
        actionName={modalName}
      />
      <div className={styles.container}>
        <button
          className={styles.additionalActions}
          onClick={(e) => handleOpen(e)}
          disabled={!shouldEnableCompleteWorkOrder}
        >
          {SUB_MODAL_NAMES.COMPLETE_WORK_ORDER}
        </button>
        <button
          className={styles.additionalActions}
          onClick={(e) => handleOpen(e)}
          disabled={shouldDisableForceAppointment}
        >
          {SUB_MODAL_NAMES.FORCE_APPOINTMENT}
        </button>
        <button
          className={styles.additionalActions}
          onClick={(e) => handleOpen(e)}
          disabled={!shouldEnableScheduledToPending}
        >
          {SUB_MODAL_NAMES.ADD_TO_RESCHEDULED_QUEUE}
        </button>
        <button
          className={styles.additionalActions}
          onClick={(e) => handleOpen(e)}
          disabled={isReadOnly}
        >
          {SUB_MODAL_NAMES.CHANGE_ADDRESS}
        </button>
        <button
          className={styles.additionalActions}
          onClick={(e) => handleOpen(e)}
          disabled={!shouldEnableResendInvoice}
        >
          {SUB_MODAL_NAMES.RESEND_INVOICE}
        </button>
        <button
          className={styles.additionalActions}
          onClick={(e) => handleOpen(e)}
          disabled={isReadOnly}
        >
          {SUB_MODAL_NAMES.CLONE_ORDER}
        </button>
        <button
          className={styles.additionalActions}
          onClick={(e) => handleOpen(e)}
          disabled={isReadOnly}
        >
          {SUB_MODAL_NAMES.INSTALLATION_IMAGES}
        </button>
        <button
          className={styles.additionalActions}
          onClick={(e) => handleOpen(e)}
          disabled={isReadOnly}
        >
          {SUB_MODAL_NAMES.SEND_TECHNICIAN_LATE_SMS}
        </button>
      </div>
    </div>
  )
}
