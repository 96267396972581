import React, { MouseEventHandler } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import CompleteWorkOrder from './modal-content/completed-work-order/CompleteWorkOrder'
import QuoteToSchedule from './modal-content/quote-to-scheduled/QuoteToScheduled'
import SplitTires from './modal-content/split-tires/SplitTires'
import ResendInvoice from './modal-content/resend-invoice/ResendInvoice'
import ResendReceipt from './modal-content/resend-receipt/ResendReceipt'
import ForceAppointment from './modal-content/force-appointment/ForceAppointment'
import PendingToScheduled from './modal-content/pending-to-scheduled/PendingToScheduled'
import AddToRescheduledQueue from './modal-content/rescheduled/AddToRescheduledQueue'
import ChangeAddress from './modal-content/change-address/ChangeAddress'
import UpdateBtToken from './modal-content/update-braintree-token/UpdateBtToken'
import CancelOrder from './modal-content/cancel-order/CancelOrder'
import ManageDiscount from './modal-content/manage-discount/ManageDiscount'
import EditVehicleModal from './modal-content/edit-vehicle/EditVehicleModal'
import DeleteVehicleModal from './modal-content/delete-vehicle/DeleteVehicleModal'
import TechAssignment from './modal-content/tech-assignment/TechAssignment'
import SendCcUpdate from './modal-content/send-cc-update/SendCcUpdate'
import AssignTrNumber from './modal-content/assign-tr-number/AssignTrNumber'
import { ITrOrderNumbersData } from '../tables/overview-summary/OrderActions'
import AdditionalTrNumber from './modal-content/additional-tr-number/AdditionalTrNumber'
import { IGetTechScheduleRouteArea } from '../../../../../schedule/components/technician-table/table-container/TechTableContainer'
import CloneOrder from './modal-content/clone-order/CloneOrder'
import DeleteDiscountModal from './modal-content/delete-discount/DeleteDiscountModal'
import ViewInstallationImages from './modal-content/view-installation-images/ViewInstallationImages'
import { SUB_MODAL_NAMES } from './constants'
import { IDiscount } from '../../../../../../../app/entities/Discount'
import SendTechnicianLateSms from './modal-content/send-technician-late-sms/SendTechnicianLateSms'

interface IWODetailModalProps {
  isOpen: boolean
  handleClosed: MouseEventHandler
  actionName: string
  techInfo?: IGetTechScheduleRouteArea
  date?: string
  trNumbers?: ITrOrderNumbersData
  triggerRerender?: () => void
  isDeleteOrderVehicleButtonVisible?: boolean
  orderVehicleObjId?: string
  selectedDiscountForEdit?: IDiscount
}
export default function WODetailsSubModal(props: IWODetailModalProps) {
  const {
    actionName,
    handleClosed,
    isOpen,
    isDeleteOrderVehicleButtonVisible,
    orderVehicleObjId,
    selectedDiscountForEdit,
    triggerRerender,
  } = props

  function onCloseHandler(event: React.MouseEvent<Element, MouseEvent>) {
    handleClosed(event)
  }

  const whichModalContent = (actionName: string) => {
    switch (actionName) {
      case SUB_MODAL_NAMES.ASSIGN:
        return (
          props.trNumbers && (
            <AssignTrNumber
              handleClose={handleClosed}
              trOrderNumber={props.trNumbers.trOrderNum ?? ''}
            />
          )
        )
      case SUB_MODAL_NAMES.SAVE:
        return (
          props.trNumbers && (
            <AdditionalTrNumber
              handleClose={handleClosed}
              addTrOrderNumber={props.trNumbers.addTrOrderNum ?? ''}
            />
          )
        )
      case SUB_MODAL_NAMES.COMPLETE_WORK_ORDER:
        return <CompleteWorkOrder handleClose={handleClosed} />
      case SUB_MODAL_NAMES.RESEND_RECEIPT:
        return <ResendReceipt handleClose={handleClosed} />
      case SUB_MODAL_NAMES.FORCE_APPOINTMENT:
        return <ForceAppointment handleClose={handleClosed} />
      case SUB_MODAL_NAMES.QUOTE_TO_SCHEDULED:
        return <QuoteToSchedule handleClose={handleClosed} />
      case SUB_MODAL_NAMES.SEND_CUSTOMER_CC_UPDATE:
        return <SendCcUpdate handleClose={handleClosed} />
      case SUB_MODAL_NAMES.ADD_TO_RESCHEDULED_QUEUE:
        return <AddToRescheduledQueue handleCancel={handleClosed} />
      case SUB_MODAL_NAMES.PENDING_TO_SCHEDULED:
        return <PendingToScheduled handleClose={handleClosed} />
      case SUB_MODAL_NAMES.CLONE_ORDER:
        return <CloneOrder handleClose={handleClosed} />
      case SUB_MODAL_NAMES.CHANGE_ADDRESS:
        return <ChangeAddress handleClose={handleClosed} />
      case SUB_MODAL_NAMES.UPDATE_BRAINTREE_TOKEN:
        return <UpdateBtToken handleClose={handleClosed} />
      case SUB_MODAL_NAMES.SPLIT_TIRES:
        return <SplitTires handleClose={handleClosed} />
      case SUB_MODAL_NAMES.RESEND_INVOICE:
        return <ResendInvoice handleClose={handleClosed} />
      case SUB_MODAL_NAMES.CANCEL_ORDER:
        return <CancelOrder handleClose={handleClosed} />
      case SUB_MODAL_NAMES.ADD_DISCOUNT:
        return <ManageDiscount handleClose={handleClosed} />
      case SUB_MODAL_NAMES.SEND_TECHNICIAN_LATE_SMS:
        return <SendTechnicianLateSms handleClose={handleClosed} />
      case SUB_MODAL_NAMES.EDIT_ORDER_VEHICLE:
        return (
          <EditVehicleModal
            handleClose={handleClosed}
            triggerRerender={triggerRerender}
            isDeleteOrderVehicleButtonVisible={
              isDeleteOrderVehicleButtonVisible
            }
            orderVehicleObjId={orderVehicleObjId}
          />
        )
      case SUB_MODAL_NAMES.DELETE_DISCOUNT:
        return (
          <DeleteDiscountModal
            handleClose={handleClosed}
            triggerRerender={triggerRerender}
            discountObjectId={selectedDiscountForEdit?.objectId}
          />
        )
      case SUB_MODAL_NAMES.EDIT_DISCOUNT:
        return (
          // <EditDiscountModal
          //   handleClose={handleClosed}
          //   triggerRerender={triggerRerender}
          //   discountObjectId={discountObjectId}
          // />
          <ManageDiscount
            handleClose={handleClosed}
            discountForEdit={selectedDiscountForEdit}
          />
        )
      case SUB_MODAL_NAMES.DELETE_VEHICLE:
        return (
          <DeleteVehicleModal
            handleClose={handleClosed}
            triggerRerender={triggerRerender}
            isDeleteOrderVehicleButtonVisible={
              isDeleteOrderVehicleButtonVisible
            }
            orderVehicleObjId={orderVehicleObjId}
          />
        )
      case SUB_MODAL_NAMES.TECH_ASSIGNMENT:
        return props.techInfo && props.date && triggerRerender ? (
          <TechAssignment
            handleClose={handleClosed}
            techInfo={props.techInfo}
            date={props.date}
            triggerRerender={triggerRerender}
          />
        ) : null
      case SUB_MODAL_NAMES.INSTALLATION_IMAGES:
        return <ViewInstallationImages handleClose={handleClosed} />
    }
  }
  //edit order vehicles

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseHandler}
      >
        <Box className={styles.scheduleModal}>
          <div className={`${styles.scheduleModalPageTitle} font--bold`}>
            {actionName === SUB_MODAL_NAMES.ASSIGN
              ? 'Assign TR Order Number'
              : actionName === SUB_MODAL_NAMES.SAVE
                ? 'Assign Additional TR Number'
                : actionName}
          </div>
          {whichModalContent(actionName)}
        </Box>
      </Modal>
    </div>
  )
}
