export function sendCustomerTechnicianLateSms(
  orderId: string,
  username: string,
) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/sms/technician-late`,
    body: {
      orderId,
      username,
    },
  }
}
