import Checkbox from '@mui/material/Checkbox'
import { useState, useContext } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { sendCustomerTechnicianLateSms } from './api'
import { UserContext } from '../../../../../../../base/components/base-container/BaseContainer'

export default function SendTechnicianLateSms(props: any) {
  const { user } = useContext(UserContext)
  const { id } = useParams()
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { setToastStatus, toastStatus } = useContext(ToastNotificationContext)

  async function handleSendUpdate() {
    setIsLoading(true)
    try {
      if (!id) {
        throw new Error('There is no orderId in the url.')
      }
      await fetchData<void>(
        sendCustomerTechnicianLateSms(id, user?.email || ''),
      )
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Text message sent to customer! ',
        severity: 'success',
      })
      props.handleClose()
    } catch (error) {
      console.error(error)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Error sending text message to customer! ',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className={styles.inputsContainer}>
      <div className={styles.checkBoxContainer}>
        <Checkbox
          value={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <div>Yes, I want to send the customer 'Tech Late' text message</div>
      </div>
      {isLoading && (
        <div className={styles.progressContainer}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Send'
          disabled={!isChecked || isLoading}
          onClick={() => handleSendUpdate()}
        />
      </div>
    </div>
  )
}
