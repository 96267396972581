import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import AddressSearchWithTireQuant from '../address-search/AddressSearchWithTireQuantity'
import {
  ISchedulerStateProps,
  initialStateProps,
} from '../../../schedule/components/schedule-modal/components/container-modal/initialData'
import { AppointmentSlots } from '../../../schedule/components/schedule-modal/components/appointment-slot/AppointmentSlots'
import { MODES } from '../../../global/constants/scheduler'

function AvailabilityByAddress() {
  const [isZipCodeServiced, setIsZipCodeServiced] = useState<any>(undefined)
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const [schedulerState, setSchedulerState] = useState<ISchedulerStateProps>({
    ...initialStateProps,
  })
  const ref = useRef(undefined)

  function displayAvailabilityCalendar() {
    setIsCalendarVisible(true)
  }

  useEffect(() => {
    if (!isZipCodeServiced) {
      setIsCalendarVisible(false)
    }
  }, [isZipCodeServiced])

  return (
    <div className={styles.mainContainer}>
      <AddressSearchWithTireQuant
        isZipCodeServiced={isZipCodeServiced}
        setIsZipCodeServiced={setIsZipCodeServiced}
        displayAvailabilityCalendar={displayAvailabilityCalendar}
        schedulerState={schedulerState}
        setSchedulerState={setSchedulerState}
      />
      {isCalendarVisible && schedulerState.customerInformation && (
        <>
          <AppointmentSlots
            schedulerState={schedulerState}
            ref={ref}
            setSchedulerState={setSchedulerState}
            setDisabled={() => {}}
            mode={MODES.SCHEDULER}
            toggleToastError={() => {}}
            viewOnly={true}
          />
          <div className={styles.readOnly}>CALENDAR IS READ ONLY</div>
        </>
      )}
    </div>
  )
}

export default AvailabilityByAddress
