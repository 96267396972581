const SUB_MODAL_NAMES = {
  ADD_DISCOUNT: 'Add Discount',
  ADD_TO_RESCHEDULED_QUEUE: 'Add to Rescheduled Queue',
  ASSIGN: 'Assign',
  CANCEL_ORDER: 'Cancel Order',
  CHANGE_ADDRESS: 'Change Address',
  CLONE_ORDER: 'Clone Order',
  COMPLETE_WORK_ORDER: 'Complete Work Order',
  DELETE_DISCOUNT: 'Delete Discount',
  DELETE_VEHICLE: 'Delete Vehicle',
  EDIT_DISCOUNT: 'Edit Discount',
  EDIT_ORDER_VEHICLE: 'Edit Order: Vehicle',
  FORCE_APPOINTMENT: 'Force Appointment',
  FORCE_MOVE: 'Force Move',
  INSTALLATION_IMAGES: 'Installation Images',
  PENDING_TO_SCHEDULED: 'Pending to Scheduled',
  QUOTE_TO_SCHEDULED: 'Quote To Scheduled',
  REASSIGN: 'Reassign',
  RESEND_INVOICE: 'Resend Invoice',
  RESEND_RECEIPT: 'Resend Receipt',
  SAVE: 'Save',
  SEND_CUSTOMER_CC_UPDATE: 'Send Customer CC Update',
  SEND_TECHNICIAN_LATE_SMS: `Send 'Tech Late' SMS`,
  SPLIT_TIRES: 'Split Tires',
  TECH_ASSIGNMENT: 'Tech Assignment',
  UPDATE_BRAINTREE_TOKEN: 'Update Braintree Token',
}

export { SUB_MODAL_NAMES }
