import { Dispatch, SetStateAction, Suspense, useRef, useState } from 'react'
import { ISchedulerStateProps } from '../../../../schedule/components/schedule-modal/components/container-modal/initialData'
import { AppointmentSlots } from '../../../../schedule/components/schedule-modal/components/appointment-slot/AppointmentSlots'
import { MODES } from '../../../../global/constants/scheduler'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import styles from './styles.module.css'
import { CircularProgress } from '@mui/material'
import { fetchData } from '../../../../global/utils/fetch'
import { IOrder } from '../../../../../../app/entities/Order'
import { AvailabilityCalendarSlot } from '../../../../../../app/types/scheduling'
import {
  setShouldRequestPaymentAfterScheduling,
  setTimeSlot,
} from '../../main/api'
import ConfirmTrSalesOrderModal from '../confirm-order-modal/ConfirmTrSalesOrderModal'
import { toggleTireDisposal } from '../../../../schedule/components/schedule-modal/components/order-summary/api'

interface ITrSchedulerComponentProps {
  schedulerState: ISchedulerStateProps
  setSchedulerState: Dispatch<SetStateAction<ISchedulerStateProps>>
  isAddressUpdating: boolean | undefined
  setTrNumber: Function
}

function TrScheduler(props: ITrSchedulerComponentProps) {
  const { schedulerState, setSchedulerState, isAddressUpdating, setTrNumber } =
    props
  const [isSlotNotSelected, setIsSlotNotSelected] = useState(true)
  const [isConfirmTrSaleOrderModalOpen, setIsConfirmTrSaleOrderModalOpen] =
    useState(false)

  const ref = useRef<any>(null)
  const isConfirmDisabled = !(!isSlotNotSelected && !isAddressUpdating)

  async function confirmAppointment() {
    try {
      const { timeSlot } =
        schedulerState.selectedAvailabilityCalendarSlot as AvailabilityCalendarSlot

      setSchedulerState((prevState: ISchedulerStateProps) => ({
        ...prevState,
        shouldRequestPaymentAfterScheduling: true,
        freeOrderReason: {
          objectId: '',
          reason: '',
          isFree: false,
        },
      }))

      Promise.all([
        fetchData(
          toggleTireDisposal(
            schedulerState.orderId,
            schedulerState.orderVehicleId,
            schedulerState.tireDisposal,
            schedulerState.disposalQty,
          ),
        ),
        fetchData<IOrder>(setTimeSlot(timeSlot, schedulerState.orderId)),
        fetchData(
          setShouldRequestPaymentAfterScheduling(schedulerState.orderId, true),
        ),
      ])
    } catch {
      throw new Error('Something went wrong. Please try again.')
    } finally {
      setIsConfirmTrSaleOrderModalOpen(true)
    }
  }

  function handleClosed() {
    setIsConfirmTrSaleOrderModalOpen(false)
  }

  return (
    <div className={styles.calendarContainer}>
      <AppointmentSlots
        schedulerState={schedulerState}
        ref={ref}
        setSchedulerState={setSchedulerState}
        setDisabled={setIsSlotNotSelected}
        mode={MODES.TR_SALES_BOOKING}
        toggleToastError={() => {}}
      />
      <PrimaryButton
        buttonName='Schedule Appointment'
        onClick={confirmAppointment}
        disabled={isConfirmDisabled}
      />
      {isConfirmTrSaleOrderModalOpen && (
        <Suspense fallback={<CircularProgress />}>
          <div>
            <ConfirmTrSalesOrderModal
              isOpen={isConfirmTrSaleOrderModalOpen}
              handleClosed={handleClosed}
              schedulerState={schedulerState}
              setSchedulerState={setSchedulerState}
              setTrNumber={setTrNumber}
            />
          </div>
        </Suspense>
      )}
    </div>
  )
}

export default TrScheduler
