import styles from './styles.module.css'
import statuses from '../../../../constants/statuses.json'
import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { WoDetailContext } from '../../../../../work-orders/components/context/context'
import WorkOrderDetailModal from '../../../../../work-orders/components/work-order-detail/components/main/WorkOrderDetailModal'
import { ILocation } from '../../../../../../../app/entities/Location'
import determineOrderLateStatus from './utils/determine-order-late-status'
import { IExtendedOrder } from '../../../../../schedule/components/technician-table/table-container/TechTableContainer'
import { SERVICE_NAMES } from '../../../../constants/service'
import createTrackingLinks from '../../../../utils/tire-rack-order/create-tracking-links'
import TrackingLinksModal from './tracking-links/TrackingLinksModal'
import { CARRIERS_WITH_POSSIBLY_INVALID_INFO_ARRAY } from '../../../../constants/tire-rack-order'

interface TechCardProps {
  order: IExtendedOrder
  location: ILocation
}

const TRACK_IT_STR = 'track it'

export default function TechRouteCard(props: TechCardProps) {
  const {
    order,
    location: { timezoneOffset },
  } = props
  const {
    additionalTireRackOrderNumber,
    customer,
    orderServices,
    tireRackOrders,
    objectId,
    timeSlot,
    startTime,
    endTime,
  } = order

  const tireRackOrder =
    tireRackOrders.length < 0 ? undefined : tireRackOrders[0]
  const shippingInfo = tireRackOrder?.shipping
  const shippingMethod = shippingInfo?.method
  const shippingEta = shippingInfo?.estimated_arrival
  const deliveredOn = shippingInfo?.delivered_on
  const shippingDateToDisplay = deliveredOn ? deliveredOn : shippingEta
  const shippingStatus = getShippingStatus(shippingEta, deliveredOn)
  const trackingInfo = createTrackingLinks(tireRackOrder)
  const { trackingLinks } = trackingInfo
  const newTabTrackItId = `newTabTrackItId`
  const multiLinkTrackItId = `${objectId}-multiLinkTrackIt`

  const { workOrderId, status } = order
  const { firstName, lastName } = customer ?? {}
  const orderServiceQuantity =
    orderServices?.find(
      (orderService) => orderService.label !== SERVICE_NAMES.TIRE_DISPOSAL,
    )?.quantity ?? 0
  const { orderNumber } = tireRackOrders[0] ?? {}
  const { fromDateGenerated } = timeSlot ?? {}
  const navigate = useNavigate()
  const { queryOrderData, clearData } = useContext(WoDetailContext)
  const [modalName, setModalName] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  function getShippingStatus(eta: any, deliveredOn: any) {
    if (deliveredOn) {
      return 'Delivered'
    } else if (eta) {
      return 'ETA'
    } else {
      return ''
    }
  }

  async function handleOpen(id: string) {
    const url = `/${id}`
    navigate(url)
    setModalName('Work Order Detail')
    setModalOpen(true)
    await queryOrderData(id)
  }
  function handleClosed() {
    setModalOpen(false)
    navigate('/')
    clearData()
  }

  const orderVisualStatus = determineOrderLateStatus(
    status,
    timezoneOffset,
    startTime,
    fromDateGenerated,
  )

  const textColor =
    orderVisualStatus === 'scheduled' || 'pending_rescheduling'
      ? '#FFFFFF'
      : '#141414'
  let borderStyle = ''
  if (orderVisualStatus === 'fixed') {
    borderStyle = '1px dotted #C2C2C2'
  } else if (
    orderVisualStatus === 'scheduled' ||
    orderVisualStatus === 'pending_rescheduling'
  ) {
    borderStyle = '1px solid #C2C2C2'
  }

  const { display, backgroundColor, statusColor } = (statuses as any)[
    orderVisualStatus ?? ''
  ]
  //order time display logic
  const orderTime =
    startTime && endTime ? `${startTime} - ${endTime}` : startTime || endTime

  function generateTrackItLink(): JSX.Element {
    if (!trackingLinks || trackingLinks.length <= 0) {
      return <div />
    } else if (trackingLinks.length === 1) {
      return (
        <a
          href={trackingLinks[0]}
          target='_blank'
          rel='noreferrer'
          id={newTabTrackItId}
        >
          {TRACK_IT_STR}
        </a>
      )
    } else {
      // if there are multiple tracking links, we know that we need to
      // open a modal to display them
      return (
        <a
          href='#'
          id={multiLinkTrackItId}
        >
          {TRACK_IT_STR}
        </a>
      )
    }
  }

  // prevent work orders detail modal from opening when clicking on tracking links
  useEffect(() => {
    const ontracTrackingLink = document.getElementById(multiLinkTrackItId)
    const newTabTrackingLink = document.getElementById(newTabTrackItId)
    if (ontracTrackingLink) {
      ontracTrackingLink.addEventListener('click', function (e) {
        setModalOpen(true)
        setModalName('Tracking Links')

        e.stopPropagation()
      })
    }
    if (newTabTrackingLink) {
      newTabTrackingLink.addEventListener('click', function (e) {
        e.stopPropagation()
      })
    }
  }, [trackingLinks])

  function whichModalContent(): JSX.Element {
    if (modalName === 'Work Order Detail') {
      return (
        <WorkOrderDetailModal
          handleClosed={handleClosed}
          isOpen={modalOpen}
        />
      )
    } else if (modalName === 'Tracking Links') {
      return (
        <TrackingLinksModal
          trackingInfo={trackingInfo}
          isOpen={modalOpen}
          handleClosed={handleClosed}
        />
      )
    } else {
      return <div />
    }
  }

  return (
    <>
      {whichModalContent()}
      <div
        onClick={() => handleOpen(objectId)}
        className={styles.cardContainer}
        style={{ backgroundColor: backgroundColor, border: borderStyle }}
      >
        <div className={styles.cardTopContainer}>
          <div>
            <section className={styles.section}>
              <div className={styles.orderNumbers}>
                {workOrderId}[{orderServiceQuantity}]
              </div>
              <div className={styles.orderTime}>{orderTime}</div>
            </section>
            <section>
              <div className={styles.custName}>
                {firstName} {lastName}
              </div>
            </section>
          </div>
          <section className={styles.section}>
            <div
              className={styles.statusOfOrder}
              style={{ backgroundColor: statusColor }}
            >
              <span
                className={styles.statusText}
                style={{ color: textColor }}
              >
                {display}
              </span>
            </div>
          </section>
        </div>
        <div className={styles.cardBottomContainer}>
          <div>
            <section className={styles.section}>
              <div className={styles.shippingMethod}>
                {orderNumber} {additionalTireRackOrderNumber}
              </div>
            </section>
            <section className={styles.section}>
              <div className={styles.shippingMethod}>{shippingMethod}</div>
            </section>
            {!CARRIERS_WITH_POSSIBLY_INVALID_INFO_ARRAY.includes(
              shippingMethod,
            ) ? (
              <section className={styles.section}>
                <div className={styles.shippingInfo}>
                  <div className={styles.shippingStatus}>{shippingStatus}</div>
                  <div>{shippingDateToDisplay}</div>
                  <div>{generateTrackItLink()}</div>
                </div>
              </section>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
