import { useEffect, useState } from 'react'
import { IOrder } from '../../../../../app/entities/Order'
import styles from '../styles.module.css'
import {
  ARRIVED_STATUSES,
  CANCELLED_STATUSES,
  ON_THE_WAY_STATUSES,
  STARTED_STATUSES,
  STATUSES,
} from '../../../global/constants/order-status'
import OrderTrackerBullet from '../submodals/order-tracker-bullet/OrderTrackerBullet'
import TrackIt from '../../../global/components/track-it/TrackIt'
import { SHIPPING_METHODS } from '../../../global/constants/tire-rack-order'
import { getHasPreviousOrderBeenStarted } from '../../api'
import { fetchData } from '../../../global/utils/fetch'

interface TrackingBulletsProps {
  order: IOrder | null
}

export default function TrackingBullets(props: TrackingBulletsProps) {
  const { order } = props
  const orderObjectId = order?.objectId ?? ''
  const tireRackOrder = order?.tireRackOrders[0] ?? undefined

  const [isPrecedingRouteStarted, setIsPrecedingRouteStarted] = useState(false)

  const [orderShipped, setOrderShipped] = useState(false)
  const [orderDelivered, setOrderDelivered] = useState(false)
  const [technicianOnTheWay, setTechnicianOnTheWay] = useState(false)
  const [technicianArrived, setTechnicianArrived] = useState(false)
  const [installationStarted, setInstallationStarted] = useState(false)
  const [installationCompleted, setInstallationCompleted] = useState(false)

  useEffect(() => {
    fetchHasPreviousOrderBeenStarted(order?.objectId ?? '')

    const isShippingMethodCustomerPickUp =
      tireRackOrder?.shipping?.method === SHIPPING_METHODS.CUSTOMER_PICK_UP
    const hasOrderShipped = isShippingMethodCustomerPickUp
      ? isShippingMethodCustomerPickUp
      : Boolean(tireRackOrder?.shippingShippedOn)
    const isOrderDelivered = isShippingMethodCustomerPickUp
      ? isShippingMethodCustomerPickUp
      : Boolean(tireRackOrder?.shippingDeliveredOn)

    setOrderShipped(hasOrderShipped)
    setOrderDelivered(isOrderDelivered)
    setTechnicianOnTheWay(ON_THE_WAY_STATUSES.includes(order?.status ?? ''))
    setTechnicianArrived(ARRIVED_STATUSES.includes(order?.status ?? ''))
    setInstallationStarted(STARTED_STATUSES.includes(order?.status ?? ''))
    setInstallationCompleted(order?.status === STATUSES.completed)
  }, [order, tireRackOrder])

  async function fetchHasPreviousOrderBeenStarted(orderObjectId: string) {
    const result = await fetchData<boolean>(
      getHasPreviousOrderBeenStarted(orderObjectId),
    )
    if (result !== undefined) {
      setIsPrecedingRouteStarted(result)
    }
  }

  return (
    <div className={styles.trackingInformationContainer}>
      {CANCELLED_STATUSES.includes(order?.status ?? '') ? (
        <OrderTrackerBullet
          isActive={true}
          message={<div>Installation Cancelled</div>}
        />
      ) : (
        <>
          {orderShipped ? (
            <OrderTrackerBullet
              isActive={orderShipped}
              message={
                <div className={styles.orderShippedMessage}>
                  Order Shipped{' '}
                  {orderObjectId && tireRackOrder ? (
                    <TrackIt
                      orderObjectId={orderObjectId}
                      tireRackOrder={tireRackOrder}
                    />
                  ) : (
                    ''
                  )}
                </div>
              }
            />
          ) : (
            ''
          )}
          {orderDelivered ? (
            <OrderTrackerBullet
              isActive={orderDelivered}
              message={<div>We have your order</div>}
            />
          ) : (
            ''
          )}
          <OrderTrackerBullet
            isActive={technicianOnTheWay}
            message={
              <div>
                Technician is{' '}
                {isPrecedingRouteStarted && !technicianOnTheWay
                  ? 'one stop away'
                  : 'on the way'}
              </div>
            }
          />
          <OrderTrackerBullet
            isActive={technicianArrived}
            message={<div>Technician Arrived</div>}
          />
          <OrderTrackerBullet
            isActive={installationStarted}
            message={<div>Installation Started</div>}
          />
          <OrderTrackerBullet
            isActive={installationCompleted}
            message={<div>Installation Complete</div>}
          />
        </>
      )}
    </div>
  )
}
