import { useState, useRef, useEffect } from 'react'
import styles from '../styles.module.css'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../global/components/buttons/secondary-button/SecondaryButton'
import { CustomerInformation } from '../../../schedule/components/schedule-modal/components/customer-information/CustomerInformation'
import { TireSizeQuantity } from '../../../schedule/components/schedule-modal/components/choose-service/TireSizeQuantity'
import { AppointmentSlots } from '../../../schedule/components/schedule-modal/components/appointment-slot/AppointmentSlots'
import { PaymentInformation } from '../../../schedule/components/schedule-modal/components/payment-information/PaymentInformation'
import { ConfirmAppointment } from '../../../schedule/components/schedule-modal/components/confirm-appointment/ConfirmAppointment'
import ConfirmationScreen from '../../../schedule/components/schedule-modal/components/appointment-is-confirmed/ConfirmationScreen'
import ToastNotification, {
  IAlertStatusProps,
} from '../../../global/components/toast-alert/ToastNotification'
import logo from '../../../assets/images/logo-285x65.png'
import { ISchedulerStateProps } from '../../../schedule/components/schedule-modal/components/container-modal/initialData'
import ServiceDropDown from '../../../global/components/service-drop-down/ServiceDropDown'
import { MODES } from '../../../global/constants/scheduler'
import { RESCHEDULE_REASONS } from '../../../global/constants/orders'
import ComplianceLinks from '../../../global/components/compliance-links/ComplianceLinks'
import LogRocket from 'logrocket'

const scheduleRoutes = [
  { title: 'Customer Information', path: 'customer-info' },
  { title: 'Choose a Service', path: 'choose-service' },
  { title: 'Select an Appointment Slot', path: 'appointment-slots' },
  { title: 'Payment Information', path: 'payment-information' },
  { title: 'Confirm Appointment Information', path: 'confirm-appointment' },
  { title: 'Thank you!', path: 'appointment-confirmation' },
]
const rescheduleRoutes = [
  { title: 'Select an Appointment Slot', path: 'appointment-slots' },
  { title: 'Thank you!', path: 'appointment-confirmation' },
]
interface ICustomerSchedulerProps {
  schedulerState: ISchedulerStateProps
  setSchedulerState: Function
  mode: MODES
}
function Logo() {
  return (
    <img
      src={logo}
      alt='logo'
      className={styles.logo}
    />
  )
}
export default function CustomerScheduler(props: ICustomerSchedulerProps) {
  const { schedulerState, setSchedulerState, mode } = props

  const [alertStatus, setAlertStatus] = useState<IAlertStatusProps>({
    isOpen: false,
    message:
      'There has been an error, please try again. If problem persists, contact customer support at 833-702-8764 ',
    severity: 'error',
  })
  const toggleAlert = (open: boolean) => {
    setAlertStatus({ ...alertStatus, isOpen: open })
  }
  const [routes] = useState(
    isModeCustomerReschedule() ? rescheduleRoutes : scheduleRoutes,
  )
  const [routeIndex, setRouteIndex] = useState(0)
  const [nextDisabled, setNextDisabled] = useState(true)
  const ref = useRef<any>(null)

  function forwardClick() {
    if (ref.current !== null) {
      ref.current.handleNextClick((forward: boolean) => {
        if (forward) {
          const newRouteIndex = routeIndex + 1
          setRouteIndex(newRouteIndex)
        }
      })
    }
  }
  function backClick() {
    const newRouteIndex = routeIndex - 1
    setRouteIndex(newRouteIndex)
  }

  function isModeCustomerReschedule() {
    return mode === MODES.CUSTOMER_RESCHEDULE
  }

  useEffect(() => {
    const shouldDisplayAppointmentConfirmation =
      schedulerState &&
      schedulerState.status === 'scheduled' &&
      mode !== MODES.CUSTOMER_RESCHEDULE
    if (shouldDisplayAppointmentConfirmation) {
      setRouteIndex(5)
    }
  }, [schedulerState])

  useEffect(() => {
    const { firstName, lastName, email } = schedulerState.customerInformation
    const customerName = `${firstName} ${lastName}`

    LogRocket.identify(schedulerState.orderId, {
      name: customerName,
      email,

      role: 'customer', // not a formal role in the system but useful for LogRocket
    })
  }, [schedulerState])

  function renderComponent(path: string) {
    switch (path) {
      case 'customer-info':
        return (
          <CustomerInformation
            schedulerState={schedulerState}
            setSchedulerState={setSchedulerState}
            ref={ref}
            setDisabled={setNextDisabled}
            mode={mode}
            toggleToastError={toggleAlert}
          />
        )
      case 'choose-service':
        return (
          <TireSizeQuantity
            schedulerState={schedulerState}
            ref={ref}
            setSchedulerState={setSchedulerState}
            setDisabled={setNextDisabled}
            mode={mode}
            toggleToastError={toggleAlert}
          />
        )
      case 'appointment-slots':
        return (
          <AppointmentSlots
            schedulerState={schedulerState}
            ref={ref}
            setSchedulerState={setSchedulerState}
            setDisabled={setNextDisabled}
            rescheduleReason={
              isModeCustomerReschedule()
                ? RESCHEDULE_REASONS.customer_requested
                : undefined
            }
            mode={mode}
            toggleToastError={toggleAlert}
          />
        )
      case 'payment-information':
        return (
          <PaymentInformation
            schedulerState={schedulerState}
            ref={ref}
            setSchedulerState={setSchedulerState}
            setDisabled={setNextDisabled}
            mode={mode}
            toggleToastError={toggleAlert}
          />
        )
      case 'confirm-appointment':
        return (
          <ConfirmAppointment
            schedulerState={schedulerState}
            setSchedulerState={setSchedulerState}
            setRouteIndex={setRouteIndex}
            setDisabled={setNextDisabled}
            ref={ref}
            mode={mode}
            toggleToastError={toggleAlert}
          />
        )
      case 'appointment-confirmation':
        return (
          <ConfirmationScreen
            schedulerState={schedulerState}
            setSchedulerState={setSchedulerState}
            setDisabled={setNextDisabled}
            mode={mode}
          />
        )
      default:
        return (
          <CustomerInformation
            schedulerState={schedulerState}
            setSchedulerState={setSchedulerState}
            ref={ref}
            setDisabled={setNextDisabled}
            mode={mode}
            toggleToastError={toggleAlert}
          />
        )
    }
  }

  function renderButtons(): JSX.Element[] {
    let buttons: JSX.Element[] = []

    // If the conditions to display the 'confirm' button have changed,
    // renderComplianceLinks also needs to be updated
    if (mode === MODES.CUSTOMER) {
      if (routeIndex !== 5) {
        buttons.push(
          <SecondaryButton
            disabled={routeIndex === 0}
            buttonName='Back'
            onClick={backClick}
          />,
        )
      }
      if (routeIndex !== 4 && routeIndex !== 5) {
        buttons.push(
          <PrimaryButton
            disabled={nextDisabled}
            buttonName='Next'
            onClick={forwardClick}
          />,
        )
      }
      if (routeIndex === 4) {
        // If the conditions to display this button have changed, renderComplianceLinks also needs to be updated
        buttons.push(
          <PrimaryButton
            disabled={nextDisabled}
            buttonName='Confirm'
            onClick={forwardClick}
          />,
        )
      }
    } else if (isModeCustomerReschedule()) {
      if (routeIndex !== 0) {
        buttons.push(
          <SecondaryButton
            disabled={routeIndex === 0}
            buttonName='Reschedule'
            onClick={backClick}
          />,
        )
      } else {
        buttons.push(<div />)
      }

      if (routeIndex !== 1) {
        // If the conditions to display this button have changed, renderComplianceLinks also needs to be updated
        buttons.push(
          <PrimaryButton
            disabled={nextDisabled}
            buttonName='Confirm'
            onClick={forwardClick}
          />,
        )
      }
    }

    // this prevents the "missing keys" error in the console
    const buttonsWithKeys = buttons.map((button, index) => {
      return { ...button, key: index.toString() }
    })

    return buttonsWithKeys
  }

  function renderComplianceLinks(): JSX.Element {
    const complianceLinks = <ComplianceLinks />

    // If the conditions to display the "confirm" button have changed, this condition also needs updated
    const isConfirmButtonDisplayed =
      (mode === MODES.CUSTOMER && routeIndex === 4) ||
      (isModeCustomerReschedule() && routeIndex !== 1)

    if (isConfirmButtonDisplayed) {
      return complianceLinks
    }

    return <></>
  }

  return (
    <div className={styles.customerSchedulePage}>
      <div className={styles.flowContainer}>
        <ToastNotification
          severity={alertStatus.severity}
          open={alertStatus.isOpen}
          message={alertStatus.message}
          handleClose={() => toggleAlert(false)}
        />
        <div className={`${styles.customerSchedulePageTitle} font--bold`}>
          <Logo />
          <div className={styles.titleAndServiceDropDownContainer}>
            {routes[routeIndex].title}
            {routes[routeIndex].title === 'Choose a Service' && (
              <>
                <ServiceDropDown
                  mode={MODES.CUSTOMER}
                  defaultValue={schedulerState.service}
                  onServiceChange={(newValue: string) => {
                    setSchedulerState((prevState: ISchedulerStateProps) => ({
                      ...prevState,
                      service: newValue,
                    }))
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className={styles.pageContainer}>
          {renderComponent(routes[routeIndex].path)}
        </div>
        <div className={styles.complianceAndButtonContainer}>
          <div className={styles.complianceLinks}>
            {renderComplianceLinks()}
          </div>
          <div className={styles.buttonContainer}>{renderButtons()}</div>
        </div>
      </div>
    </div>
  )
}
