// This file is duplicated at app/constants/ssm.ts. Please keep them in sync
const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME ?? ''

//! make sure to grant permission to the task role in trmi-app.ts to the `SSM PARAMETER PERMISSIONS` for the parameters you create
const SSM_PARAMETER_NAMES = {
  MOBILE_APP_VERSION_CODE: `/trmi/${ENVIRONMENT_NAME}/mobile/version-code`,
  SCHEDULING_PERFORMANCE_FLAG_VALUES: `/trmi/${ENVIRONMENT_NAME}/frontend/scheduling-performance-flag-values`,
}

export { SSM_PARAMETER_NAMES }
